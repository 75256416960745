.widget--popup {
    position: relative;
    color: var(--color-default-text);
    background-color: transparent;
    min-height: var(--size-widget-small);
    min-width: var(--size-widget-small);
    border-radius: var(--border-radius-small);

    &:not([role]) {
        width: 100%;
    }

    &[role="context-menu"] {
        position: absolute;
        min-width: 0;
        min-height: 0;
    }

    &.primary &__icon,
    &.primary &__label {
        color: var(--color-action-text);
        background-color: var(--color-action-500);

        &:hover {
            background-color: var(--color-action-600);
        }
    }
    
    &.secondary &__icon,
    &.secondary &__label {
        color: var(--color-decor-text);
        background-color: var(--color-decor-500);

        &:hover {
            background-color: var(--color-decor-800);
        }
    }

    &__inner {
        display: flex;
        border-radius: inherit;
        height: var(--size-widget-small);
    }

    &__value {
        background-color: var(--color-default);
        color: var(--color-default-text);
        border-radius: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        padding: 0 0 0 0.5rem;
        border: 1px solid var(--color-default-200);
        width: 0;
        flex-grow: 1;
        line-height: var(--size-widget-small);
        text-align: left;
        
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:hover {
            border-color: var(--color-action-500);
        }

        &__icon {
            float: right;
            display: inline-block;
            width: var(--size-widget-small);
            line-height: var(--size-widget-small);
            text-align: center;

            &::before {
                position: relative;
                top: -2px;
            }
        }
    }

    &__clear {
        width: var(--size-widget-small);
        cursor: pointer;
        border: 0;
        padding: 0;
        line-height: var(--size-widget-small);
        display: block;
        text-align: center;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        color: var(--color-default-text);
        background-color: var(--color-default-200);

        &:hover {
            background-color: var(--color-action-500);
            color: var(--color-action-text);
        }
    }

    &__label {
        border: 0;
        padding: 0 0.6rem;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        color: inherit;
        line-height: var(--size-widget-small);
        border-radius: var(--border-radius-medium);
       
        .fa {
            margin-right: 0.5rem;
        }

        &:hover {
            background-color: var(--color-default-100);
        }
    }

    &__icon {
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        line-height: var(--size-widget-small);
        width: var(--size-widget-small);
        max-width: var(--size-widget-small);
        min-width: var(--size-widget-small);
        text-align: center;
        display: block;
        border-radius: var(--border-radius-medium);

        &:hover {
            background-color: var(--color-default-100);
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: auto;
        opacity: 0;
        z-index: 900;
    }

    &__search {
        position: relative;

        &::before {
            position: absolute;
            content: '\f002';
            display: block;
            font-family: 'FontAwesome';
            color: var(--color-default-200);
            line-height: var(--size-widget-small);
            width: var(--size-widget-small);
            text-align: center;
            height: var(--size-widget-small);
            left: 0;
            top: 0;
        }

        &__input {
            display: block;
            width: 100%;
            border: 0;
            background-color: transparent;
            line-height: var(--size-widget-small);
            padding: 0 var(--size-widget-small);

            &:focus {
                border-color: var(--color-action-500);
            }
        }

        &__clear {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            line-height: var(--size-widget-small);
            text-align: center;
            width: var(--size-widget-small);
            z-index: 10;
            border: 0;
            background-color: transparent;
        }
    }

    &__menu {
        z-index: 910;
        position: absolute;
        min-width: 14rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--color-default);
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius-small);
        border: 1px solid var(--color-default-200);

        &::before {
            font-family: 'FontAwesome';
            display: block;
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            font-size: 2rem;
            position: absolute;
            z-index: 10;
            color: var(--color-default);
        }

        &.top {
            top: var(--size-widget-small);

            &::before { content: '\f0d8'; top: -0.9rem; }
        }

        &.bottom {
            bottom: var(--size-widget-small);
            flex-direction: column-reverse;
    
            &::before { content: '\f0d7'; bottom: -0.7rem; }
        }

        &.left {
            left: 0;

            &::before { left: 0.5rem; }
        }
        
        &.right {
            right: 0;
            
            &::before { right: 0.5rem; }
        }
    }

    &[role="context-menu"] &__menu {
        &::before { display: none; }
        &.top { top: 0; }
        &.bottom { bottom: 0; }
    }

    &__options {
        background-color: transparent;
        overflow: auto;
        max-height: 20rem;
        padding: 0.25rem 0;
    }
    
    &[role="context-menu"] &__options {
        max-height: none;
    }

    &__separator {
        position: relative;
        line-height: var(--size-widget-small);
        padding: 0 1rem;
        color: var(--color-default-300);
        border-bottom: 1px solid var(--color-default-200);
    }

    &__option {
        width: 100%;
        display: block;
        background-color: inherit;
        color: var(--color-default-text);
        white-space: nowrap;
        text-align: left;
        line-height: var(--size-widget-small);
        padding: 0 1rem;
        border: 0;
        cursor: pointer;
        position: relative;
        z-index: 20;

        &.selected {
            &::after {
                font-family: 'FontAwesome';
                content: '\f00c';
                display: block;
                line-height: var(--size-widget-small);
                width: var(--size-widget-small);
                text-align: center;
                right: 0;
                top: 0;
                position: absolute;
            }
        }

        &:hover {
            background-color: var(--color-action-500);
            color: var(--color-action-text);
        }
    }
    
    &__option + &__separator {
        margin-top: 0.5rem;
    }

}

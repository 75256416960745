/**
 * An element for highlighting and explaining other elements
 */
// The frame's pulsating animation
@keyframes highlight-pulse {
    to {
        box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0);
    }
}

// The label's popup animation
@keyframes highlight-popup {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.modal--highlight {
    position: absolute;
    display: flex;
    z-index: 999999;
    
    &.right {
        flex-direction: row;
    }

    &.left {
        flex-direction: row-reverse;
    }
    
    &.top {
        flex-direction: column-reverse;
    }
    
    &.bottom {
        flex-direction: column;
    }

    &.right &__label {
        top: 0.5rem;
        left: calc(100% + 1.5rem);

        &:after {
            border-top: 1rem solid transparent;
            border-bottom: 1rem solid transparent;
            border-right: 1rem solid var(--color-action-500);
            top: 0%;
            right: 100%;
        }
    }
    
    &.left &__label {
        top: 0.5rem;
        right: calc(100% + 1.5rem);

        &:after {
            border-top: 1rem solid transparent;
            border-bottom: 1rem solid transparent;
            border-left: 1rem solid var(--color-action-500);
            top: 0%;
            left: 100%;
        }
    }
    
    &.top &__label {
        bottom: calc(100% + 1.5rem);
        
        &:after {
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-top: 1rem solid var(--color-action-500);
            top: 100%;
            left: 0%;
        }
    }
    
    &.bottom &__label {
        top: calc(100% + 1.5rem);
        
        &:after {
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-bottom: 1rem solid var(--color-action-500);
            bottom: 100%;
            left: 0%;
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
    }

    &__frame {
        border: 1px solid var(--color-action-500);   
        border-radius: 0.2rem;
        box-shadow: 0 0 0 0 var(--color-action-500);
        animation: highlight-pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    }

    &__label {
        transform-origin: 0% 0%;
        animation: highlight-popup 0.5s once cubic-bezier(.75, -0.5, 0, 1.75);
        border-radius: 0.2rem;
        border: 1px solid var(--color-action-500);
        background-color: var(--color-default);
        color: var(--color-default-text);
        font-weight: bold;
        padding: 1rem;
        line-height: 1.5;
        position: absolute;
        min-height: 2rem;
        min-width: 20rem;
        box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1); 

        &:after {
            position: absolute;
            content: '';
            display: block;
            width: 0;
            height: 0;
        }
    }

    &__button {
        margin-top: 0.5rem;
        margin-left: auto;
    }
}

.widget--media {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    
    &.small {
        width: 10rem;
    }

    &.small &__preview {
        height: 10rem;
    }
   
    &__placeholder {
        @include spinner;
    }

    &__preview {
        padding: var(--padding-small);
        margin: 0;
        border-top-left-radius: var(--border-radius-small);
        border-top-right-radius: var(--border-radius-small);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        border: 1px solid var(--color-default-200);
        background-color: var(--color-default-200);
        min-width: 6rem;
        min-height: 6rem;

        &.readonly {
            border-radius: var(--border-radius-small);
        }

        &:not(.readonly) {
            cursor: pointer;
            
            &:hover {
                border-color: var(--color-action-500);
                background-color: var(--color-action-500);
                color: var(--color-action-text);
            }
        }

        &__source {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            position: relative;
            z-index: 100;
        }
    }

    &__tools {
        display: flex;
    }

    &__name {
        color: var(--color-default-text);
        background-color: var(--color-default-200);
        border-bottom-left-radius: var(--border-radius-small);
        flex-grow: 1;
        padding: 0 var(--padding-small);
        white-space: nowrap;
        overflow: hidden;
        line-height: var(--size-widget-small);
        text-overflow: ellipsis;
    }

    &__clear {
        line-height: var(--size-widget-small);
        width-height: var(--size-widget-small);
        display: block;
        flex-shrink: 0;
        text-align: center;
        color: var(--color-default-text);
        background-color: var(--color-default-200);
        border-bottom-right-radius: var(--border-radius-small);
        border: 0;
        cursor: pointer;

        &:hover {
            background-color: var(--color-action-500);
            color: var(--color-action-text);
        }
    }
}

/**
 * A spinner for loading
 */
@keyframes widget--spinner {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(359deg);
    }
}

.widget--spinner {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.5);

    &.no-background {
        background-color: transparent;
    }

    &.hidden {
        display: block !important;
        opacity: 0;
        pointer-events: none;
    }

    &.embedded {
        position: absolute;
    }

    &__inner {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
    }

    &__image {
        position: absolute;
        display: block;
        width: 8rem;
        height: 8rem;
        text-align: center;
        line-height: 8rem;
        color: var(--color-decor-500);
        font-size: 2rem;
        top: calc(50% - 4rem);
        left: calc(50% - 4rem);
        animation-name: widget--spinner;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    &__messages {
        left: calc(50% + 4rem);
        top: 50%;
        position: absolute;
        transform: translate(0%, -50%);
        border-left: 1px solid var(--color-action-500);
    }
    
    &__message {
        position: relative;
        width: 180px;
        transition: color 0.25s ease;
        padding: 10px;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--color-action-500);

        &:before {
            content: '';
            display: block;
            background-color: var(--color-action-500);
            width: 0%;
            height: 100%;
            transition: width 0.25s ease;
            top: 0%;
            left: 0%;
            position: absolute;
            z-index: -1;
        }

        &.loaded {
            color: var(--color-action-text);

            &:before {
                width: 100%;
            }
        }
    }
}

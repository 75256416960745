.modal--pick-icon {
    .modal__dialog {
        max-width: 70rem;
    }

    &__search {
        margin-bottom: 1rem;
        max-width: 100%; 
    }

    &__icon {
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0.5rem;
        text-align: center;
        line-height: var(--size-widget-medium);
        float: left;
        width: var(--size-widget-medium);
        height: var(--size-widget-medium);
        cursor: pointer;
        border-radius: var(--border-radius-small);
            
        &:hover {
            background-color: var(--color-default-100);
        }
    }
}

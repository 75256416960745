/**
 * A generic label
 */
.widget--label {
    line-height: 2rem;
    height: 2rem;
    display: block;
    flex-grow: 1;
    margin: 0;
    background-color: var(--color-default-200);
    color: var(--color-default-text);
    padding: 0 0.5rem;
    border-radius: var(--border-radius-small);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid var(--color-default-200);

    > .fa {
        margin-right: 0.5rem;
    }

    &.small {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 10rem;
        max-width: 10rem;
    }
}

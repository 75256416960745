/**
 * A graphical separator
 */
.widget--separator {
    margin: 2rem 0;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-default-text);
}

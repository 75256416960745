.panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: calc(var(--width-panels) - var(--size-widget-large) - var(--padding-medium));

    @include tablet {
        body:not(.welcome) & {
            display: none;            
        }

        body.welcome & {
            width: 100%;
        }
    }

    &.loading {
        @include spinner; 
    }
    
    &__tools {
        display: flex;
        padding: 0.5rem;
        border-bottom: 1px solid var(--color-decor-800);
        position: relative;

        &__sort {
            margin-left: 0.5rem;
        }
    }

    &__items {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    &__context {
        flex-grow: 1;
        width: 100%;
    }

    &[data-drag-over] &__context {
        box-shadow: inset 0 0 0 2px var(--color-decor-text);
    }
}

html, body {
    height: 100%;
    background-color: var(--color-default-100);
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

@keyframes page__spinner__logo {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(0deg); }
    100% { transform: rotate(90deg); }
}

.page {
    margin: 0;
    padding: 0;

    &.loading &__spinner {
        opacity: 1;
        pointer-events: all;
    }

    &.logo {
        background-color: var(--color-decor-500);
        color: var(--color-decor-text);
        
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('/svg/logo_white.svg');
            opacity: 0.04;
            background-size: cover;
            background-position: center;
            pointer-events: none;
        }
    }

    &.centered {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__spinner {
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        opacity: 0;
        transition: opacity 1s ease;
        background-color: var(--color-default-100);

        &__logo {
            position: absolute;
            top: calc(50% - 5rem);
            left: calc(50% - 5rem);
            width: 10rem;
            height: 10rem;

            svg {
                fill: var(--color-action-500);
                width: 100%;
                height: 100%;
                animation: page__spinner__logo 1s ease infinite;
            }
        }
    }
}

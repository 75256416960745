.navigation--session {
    position: fixed;
    top: 1rem;
    right: 1rem;
    display: flex;
    z-index: 200;

    .widget {
        margin-left: 0.5rem;
    }
}

.list-item--user {
    color: var(--color-default-text);
    background-color: var(--color-default);
    padding: 1rem;
    margin: 0.5rem;
    position: relative;
    flex-grow: 0;
    flex-basis: calc(33.33% - 1rem);
    width: calc(33.33% - 1rem);
    transform-origin: center;
    transition: transform 0.25s ease;
    box-shadow: var(--box-shadow);
    
    @include tablet {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
    }

    @include phone {
        flex-basis: 0;
        width: auto;
    }

    &__name {
        margin: 0;
    }

    &__type {
        margin-top: 0.5rem;

        &__icon {
            margin-right: 0.5rem;
        }   
    }

    .widget--popup[role="item-menu"] {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }
}

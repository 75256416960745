@keyframes resource-editor__footer__warning__icon {
    0% { transform: scale(0.9); }
    80% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.resource-editor {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    &.loading {
        @include spinner;
    }

    &__welcome {
        padding: 4rem;
        margin-right: auto;
    }

    &__header {
        background-color: var(--color-decor-500);
        color: var(--color-decor-text);
        display: flex;
        align-items: flex-end;
        min-height: var(--size-widget-large);
        flex-shrink: 0;
    
        &__spacer {
            flex-grow: 1;
        }
        
        &__title {
            line-height: var(--size-widget-large);
            padding-right: var(--padding-large);
            flex-shrink: 0;

            &__icon {
                display: inline-block;
                width: var(--size-widget-large);
                text-align: center;
            }
        }
            
        &__tabs {
            display: flex;
        }

        &__tab {
            display: block;
            background-color: var(--color-decor-700);
            border-bottom: 1px solid transparent;
            padding: 0 var(--padding-large);
            line-height: var(--size-widget-medium);
            height: var(--size-widget-medium);
            border-top-left-radius: var(--border-radius-small);
            border-top-right-radius: var(--border-radius-small);
            font-weight: bold;
            margin-right: var(--padding-small);

            &.active {
                background-color: var(--color-default-100);
                color: var(--color-default-text);
                pointer-events: none;
            }

            &:not(.active) {
                color: var(--color-decor-text);
                background-color: var(--color-decor-600);
                border-color: var(--color-decor-500);

                &:hover {
                    background-color: var(--color-action-500);
                    color: var(--color-action-text);
                }
            }
        }
    }

    &__body {
        border-radius: 0;
        border: 0;
        padding: 1rem;
        flex-grow: 1;
        overflow: auto;

        &.centered {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > *:last-child {
           margin-bottom: var(--padding-medium); 
        }
    }

    &__footer {
        border-top: 1px solid var(--color-default-200);
        padding: 1rem;
        min-height: var(--size-widget-large);
        display: flex;
        flex-shrink: 0;

        &__warning {
            position: relative;

            &:empty {
                display: none;
            }

            &__message {
                padding: var(--padding-medium);
                position: absolute;
                bottom: 100%;
                width: var(--max-width-field-value);
                background-color: var(--color-warn-500);
                color: var(--color-warn-text);
                border-radius: var(--border-radius-small);
                line-height: 1.5;
            }

            &__icon {
                animation: resource-editor__footer__warning__icon 0.25s ease;
                cursor: pointer;
                line-height: var(--size-widget-medium);
                display: block;
                text-align: center;
                width: var(--size-widget-medium);
                background-color: var(--color-warn-500);
                color: var(--color-warn-text);
                border-radius: var(--border-radius-small);

                &:hover {
                    background-color: var(--color-warn-300);
                }
            }
        }

        &__actions {
            margin-left: auto;
            display: flex;
        }
    }
}

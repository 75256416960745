.navigation--resource-browser {
    width: 100%;
    height: 100%;
    display: flex;

    &__tabs {
        padding: 0.5rem 0 0 0;
        background-color: var(--color-decor-500);
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0.5rem 0 1rem 0;

        svg {
            fill: var(--color-decor-700);
            width: var(--size-widget-medium);
            height: var(--size-widget-medium);
        }

        &:hover {
            svg {
                fill: var(--color-action-500);
            }
        }
    }

    &__tab {
        display: block;
        margin: var(--padding-small) 0 0 var(--padding-medium);
        width: var(--size-widget-large);
        height: var(--size-widget-large);
        border-top-left-radius: var(--border-radius-small);
        border-bottom-left-radius: var(--border-radius-small);
        line-height: var(--size-widget-large);
        text-align: center;
        font-size: 2rem;
        
        &.active {
            color: var(--color-decor-text);
            background-color: var(--color-decor-600);
        }

        &:not(.active) {
            background-color: var(--color-decor-600);
            color: var(--color-decor-800);
            border-right: 1px solid var(--color-decor-500);
        }
        
        &:focus {
            outline: none;
        }

        &:hover {
            background-color: var(--color-action-500);
            color: var(--color-action-text);
        }

    }

    &__panels {
        flex-grow: 1;
        background-color: var(--color-decor-600);
        color: var(--color-decor-text);
    }
}

@keyframes spinner {
    0% { transform: scale(0); }
    70% { transform: scale(1.2); }
    100% { transform: scale(0); }
}

@mixin spinner {
    position: relative;
    pointer-events: none;
    min-height: 1rem;
    min-width: 1rem;

    &::before, &::after {
        pointer-events: none;
        position: absolute;
        display: block;
        font-family: 'FontAwesome';
        content: '\26AB';
        z-index: 21;
        font-size: 1rem;
        line-height: 1rem;
        width: 1rem;
        height: 1rem;
        text-align: center;
        top: calc(50% - 0.6rem);
        animation: spinner 1s ease infinite;
        transform: scale(0);
    }

    &::before {
        left: calc(50% - 1.1rem);;
    }
    
    &::after {
        left: calc(50% + 0.1rem);;
        animation-delay: 0.25s;
    }
}

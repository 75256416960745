@mixin desktop {
    @media(min-width: 1400px) {
        @content;
    }
}

@mixin laptop {
    @media(max-width: 1399px) {
        @content;
    }
}

@mixin tablet {
    @media(max-width: 991px) {
        @content;
    }
}

@mixin phone {
    @media(max-width: 767px) {
        @content;
    }
}
